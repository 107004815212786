import * as React from 'react';
import {TextField} from "@fluentui/react/lib/TextField";
import ResourcePanel from "./ResourcePanel";
import {PrimaryButton} from "@fluentui/react";

class StatefulApp extends React.Component {


    async getListArray() {
        return [{name: 'test', state: 'up', image: 'nginx', port: 80, create_on: '2021.4.3 31:32:34'}]
    }

    getPreview(props) {
        return (<div>{props === undefined ? "" : props.name}</div>)
    }

    getCreate() {
        return <CreateStatefulApp/>
    }

    getEdit(props) {
        return <div>{props === undefined ? "" : props.name}</div>
    }

    render() {
        return (<div>
            <ResourcePanel
                getList={this.getListArray}
                getCreate={this.getCreate}
                getPreview={this.getPreview}
                getEdit={this.getEdit}/>
        </div>)
    }
}

class CreateStatefulApp extends React.Component {

    render() {
        return (<div>
            <div style={{margin: '5px'}}>
                <TextField required label={"name"}/>
                <TextField required label={"image"}/>
                <TextField required label={"config"}/>
                <PrimaryButton text={"create"}/>
            </div>
        </div>)
    }
}

export default StatefulApp