import React from "react";
import {DetailsList, DetailsListLayoutMode, SelectionMode} from "@fluentui/react";

class Log extends React.Component {

    constructor(props) {
        super(props);
        this.state = {log: [], select: undefined};
    }

    getDetail(line) {
        return <div>
            <div>{line['path']} {line['method']}</div>
            <div>{line['query']}</div>
            <div>{line['time_used']}</div>
            <div>{new Date(line['time']).toString()}</div>
        </div>
    }

    render() {

        const columns = [{
            key: 'path', name: 'Path', fieldName: 'path', minWidth: 30, maxWidth: 100, isResizable: true,
        }, {
            key: 'method', name: 'Method', fieldName: 'method', minWidth: 60, maxWidth: 60, isResizable: true,
        }, {
            key: 'query', name: 'Query', fieldName: 'query', minWidth: 60, maxWidth: 100, isResizable: true,
        }, {
            key: 'time_used',
            name: 'Time Used',
            fieldName: 'time_used',
            minWidth: 60,
            maxWidth: 100,
            isResizable: true,
            onRender: (item) => {
                return <span>{(item['time_used'] * 1000).toFixed(2) + "ms"}</span>;
            },
        }, {
            key: 'time', name: 'Time', fieldName: 'time', isResizable: true, onRender: (item) => {
                return <span>{new Date(item['time']).toString()}</span>;
            },
        },]

        return (<div>
            <DetailsList
                items={this.state.log}
                compact={true}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={this._getKey}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onItemInvoked={(item) => {
                    this.setState({select: item})
                }}
            />
            {this.state.select !== undefined && this.getDetail(this.state.select)}

        </div>)
    }

    componentDidMount() {
        fetch(this.backend_path + '/log/get_log?component=api_gateway&limit=100&start_time=' + (Math.floor(Number(new Date()) / 1000) - 1000))
            .then(res => res.json())
            .then(res => {
                this.setState({log: res['log']})
            })
    }
}

export default Log