import {CommandBar} from "@fluentui/react/lib/CommandBar";
import React from "react";
import {Nav} from "@fluentui/react/lib/Nav";
import {DefaultButton, Panel} from "@fluentui/react";
import './ResourcePanel.css'

class ResourcePanel extends React.Component {
    /**
     * props:
     *  getList() :get list, must be async
     *  getCreate(close_function)
     *  getEdit(item,close_function)
     *  getPreview(item)
     * */
    constructor(props) {
        super(props);
        this.state = {
            list: [], // object list
            nav_select_key: "", // selected key name for fluent ui nav
            modePreview: false, modeEdit: false, modeCreate: false, // mode
            selectObject: null, // selected full object
        };
    }

    switchMode(mode) {
        this.setState({
            modePreview: false, modeEdit: false, modeCreate: false,
        })
        switch (mode) {
            case 'preview':
                this.setState({modePreview: true})
                break
            case 'edit':
                if (this.state.selectObject != null) {
                    this.setState({modeEdit: true})
                }
                break
            case 'create':
                this.setState({modeCreate: true})
                break
            default:
        }
    }

    onSelectItem(item) {
        this.setState({
            selectObject: item,
            nav_select_key: item.key
        })
        this.switchMode('preview')
    }


    // get nav list
    // eg: add key
    getNavList(list) {
        for (let l of list) {
            if (l.key === undefined) {
                l.key = l.name
            }
        }
        return list
    }

    updateList() {
        if (this.props.getList !== undefined) {
            this.props.getList().then((l) => {
                this.setState({list: this.getNavList(l)})

                // update selectObject after edit
                if (this.state.selectObject != null) {
                    for (let i of this.state.list) {
                        if (i.name === this.state.selectObject.name) {
                            this.setState({selectObject: i})
                        }
                    }
                }
            })
        }
    }


    componentDidMount() {
        this.updateList()
    }

    getMainFrame() {
        if (this.state.list.length === 0) {
            return <div>empty list</div>
        }
        return <div className={'resource_panel_main_frame'}>

            {/********     left bar       *******/}
            <div className={'card'} style={{height: '100%', backgroundColor: 'white'}}>
                <div>
                    <Nav
                        groups={[{
                            links: this.state.list
                        }]}
                        onLinkClick={(_, item) => {
                            this.onSelectItem(item)
                        }}
                        selectedKey={this.state.nav_select_key}
                    />
                </div>

            </div>


            {/********     right content       *******/}
            <div style={{backgroundColor: 'white'}} className={'card'}>
                {/********     preview       *******/}
                {this.state.modePreview && this.state.selectObject != null && (this.props.getPreview === undefined ?
                    <div/> : <div>
                        {this.props.getPreview(this.state.selectObject)}
                    </div>)}


                {/*********     edit       *********/}
                {this.state.modeEdit && (this.props.getEdit === undefined ?
                    <div>can't edit</div> : this.props.getEdit(this.state.selectObject, () => {
                        this.updateList()
                        this.switchMode('preview')
                    }))}
            </div>

        </div>
    }

    render() {
        return (<div style={{backgroundColor: 'rgb(243, 242, 241)', height: '100%'}}>

            {/********     header       *******/}
            <div>

                <CommandBar className={'card'}
                            items={[{
                                key: 'newItem', text: 'New', cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                                iconProps: {iconName: 'Add'}, onClick: () => {
                                    this.switchMode('create')
                                },
                            }, {
                                key: 'editItem', text: 'Edit', cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                                iconProps: {iconName: 'Edit'}, onClick: () => {
                                    this.switchMode('edit')
                                },
                            }, {
                                key: 'deleteItem', text: 'Delete', cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                                iconProps: {iconName: 'Delete'}, onClick: () => {

                                },
                            }]}
                    //overflowItems={this._overflowItems}
                    //overflowButtonProps={this.overflowProps}
                            farItems={[{
                                key: 'info', text: 'Info', // This needs an ariaLabel since it's icon-only
                                ariaLabel: 'Info', iconOnly: true, iconProps: {iconName: 'Info'}, //onClick: () => console.log('Info'),
                            },]}
                            ariaLabel="Inbox actions"
                            primaryGroupAriaLabel="Email actions"
                            farItemsGroupAriaLabel="More actions"
                />
            </div>

            {this.getMainFrame()}

            {/*********     create panel     *********/}
            <Panel
                headerText="创建"
                isOpen={this.state.modeCreate}
                onDismiss={() => {
                    this.switchMode('preview')
                    this.updateList()
                }}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={() => {
                    return <div>
                        <DefaultButton onClick={() => {
                            this.switchMode('preview')
                            this.updateList()
                        }} text={"关闭"}/>
                    </div>
                }}
                isLightDismiss={true}
                //isFooterAtBottom={true}
            >
                {this.props.getCreate === undefined ? <div/> : this.props.getCreate(() => {
                    this.switchMode('preview')
                })}
            </Panel>


        </div>)
    }
}


export default ResourcePanel

