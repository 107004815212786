import React from "react";
import './Function.css'

import {Label} from '@fluentui/react/lib/Label';
import {TextField} from '@fluentui/react/lib/TextField';
import {ChoiceGroup, DefaultButton, PrimaryButton, Slider} from "@fluentui/react";
import {Stack} from '@fluentui/react/lib/Stack';
import ResourcePanel from "./ResourcePanel";
import Editor from "@monaco-editor/react";

class Function extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.code = 'print("hello world")';
    }

    getPreview(item) {
        return <FunctionPreview fn_detail={item}/>
    }

    getEdit(item, close_function) {
        return <FunctionEdit fn_detail={item} cancelEdit={close_function} code={item.code}/>
    }

    getCreate(close_function) {
        return <FunctionEdit fn_create={true} cancelEdit={close_function} code={this.code}/>
    }

    render() {
        return (<ResourcePanel
            getList={this.getFunctionList.bind(this)}
            getPreview={this.getPreview}
            getEdit={this.getEdit}
            getCreate={this.getCreate.bind(this)}
        />);
    }

    componentDidMount() {
    }

    async getFunctionList() {
        return fetch(this.backend_path + '/function/')
            .then(res => res.json())
    }
}

class FunctionPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {test_run_res: undefined}
    }

    function_test() {
        //let name = document.getElementsByName("function_name")[0].value
        let name = this.props.fn_detail.name
        fetch(this.backend_path + '/function/run?function_name=' + name + '&param={}')
            .then(res => res.json())
            .then(res => {
                this.setState({test_run_res: res})
            })
    }

    render() {
        return (<div>
            <div className="fn_detail_content">

                <Stack horizontal>
                    <Stack>
                        <Label disabled>函数名</Label>
                        <Label disabled>函数运行环境</Label>
                        <Label disabled>运行配置</Label>
                        <Label disabled>超时</Label>
                    </Stack>

                    <Stack>
                        <div style={{minWidth: '20px'}}/>
                    </Stack>

                    <Stack>
                        <Label>{this.props.fn_detail.name}</Label>
                        <Label>{this.props.fn_detail.language}</Label>
                        <Label>128MB</Label>
                        <Label>60s</Label>
                    </Stack>
                </Stack>


                <div className="detail_code">
                    <pre>{this.props.fn_detail.code}</pre>
                </div>

                {/*<div>*/}
                {/*    <PrimaryButton text={"编辑"} onClick={this.props.onEdit}/>*/}
                {/*</div>*/}

                <div>
                    <PrimaryButton onClick={() => this.function_test()}>测试函数</PrimaryButton>
                </div>
                <div>
                    {this.state.test_run_res !== undefined && <CodeRunResult res={this.state.test_run_res}/>}
                </div>
            </div>
        </div>)
    }
}

class CodeRunResult extends React.Component {

    constructor(props) {
        super(props);
        this.xtermRef = React.createRef()
    }

    render() {
        if (this.props.res === undefined) {
            return <div></div>
        }
        return (<div>
            {this.props.res.hasOwnProperty('error') && <div>
                <Label disabled>error:</Label>
                <div>{this.props.res['error']}</div>
            </div>}
            {this.props.res.hasOwnProperty('code_print') && <div>
                <Label disabled>output:</Label>
                <div>
                    <pre className={'detail_code'}>{this.props.res['code_print']}</pre>
                </div>
            </div>}
            {this.props.res.hasOwnProperty('code_stderr') && <div>
                <Label disabled>code_stderr:</Label>
                <div>
                    <pre className={'detail_code'}>{this.props.res['code_stderr']}</pre>
                </div>
            </div>}
            <Label disabled>time_used: </Label>{this.props.res['time_used']}
        </div>)
    }
}

class FunctionEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            function_name: "", language: "python", runtime: 'ns', config: "small", timeout: 20, code: this.props.code
        }

        if (!this.props.fn_create) {
            this.state = {
                function_name: this.props.fn_detail.name,
                language: this.props.fn_detail.language,
                runtime: this.props.fn_detail.runtime,
                config: this.props.fn_detail.config,
                timeout: this.props.fn_detail.timeout,
                code: this.props.code
            }
        }
    }

    onCodeChange(code) {
        this.setState({
            code: code
        })
    }

    onSave() {
        let url = this.backend_path + "/function/"
        this.postData(url, this.state).then()
    }

    render() {
        return (<div>
            <div className={"fn_edit_content"}
                // style={{display: (this.props.fn_edit ? "" : "none")}}
            >
                <div className={"fn_content"}>
                    <Label>在此配置函数</Label>
                    <div><Label disable>函数名</Label>
                        <TextField type={"text"} name={"function_name"} disabled={!this.props.fn_create}
                                   value={this.state.function_name}
                                   onChange={(k, v) => {
                                       this.setState({
                                           function_name: v
                                       })
                                   }}/>
                    </div>
                    <div>
                        <ChoiceGroup defaultSelectedKey={this.state.language}
                                     options={[{key: 'python', text: 'python'}, {
                                         key: 'node', text: 'node'
                                     }, {key: 'shell', text: 'shell'}]}
                                     label="选择函数运行环境:"
                                     required={true}
                                     onChange={(k, v) => {
                                         this.setState({
                                             language: v.key
                                         })
                                     }}
                        />
                    </div>
                    <div>
                        <ChoiceGroup defaultSelectedKey={this.state.runtime}
                                     options={[{key: 'ns', text: 'ns'}, {key: 'host', text: 'host'},]}
                                     label="选择函数运行时:"
                                     required={true}
                                     onChange={(k, v) => {
                                         this.setState({
                                             runtime: v.key
                                         })
                                     }}
                        />
                    </div>
                    <div>
                        <ChoiceGroup defaultSelectedKey={this.state.config}
                                     options={[{key: 'small', text: '128MB'}, {
                                         key: 'middle', text: '256MB'
                                     }, {key: 'large', text: '512MB'}, {key: 'xlarge', text: '1G'}]}
                                     label="选择函数运行配置:"
                                     required={true}
                                     onChange={(k, v) => {
                                         this.setState({
                                             config: v.key
                                         })
                                     }}
                        />
                    </div>
                    <div>
                        <Slider label="超时(s):" min={0} max={120} step={1} defaultValue={this.state.timeout} showValue
                                snapToStep
                                onChanged={(i, j) => {
                                    this.setState({
                                        timeout: j
                                    })
                                }}
                        />
                    </div>

                    <div>
                        <div className={"fn_editor"}>
                            {/*<CodeMirror*/}
                            {/*    value={this.props.code}*/}
                            {/*    options={{*/}
                            {/*        theme: 'monokai', keyMap: 'sublime', mode: this.state.language*/}
                            {/*    }}*/}
                            {/*    onChange={code => this.onCodeChange(code)}*/}
                            {/*/>*/}

                            <Editor
                                height="30vh"
                                defaultLanguage={this.state.language}
                                //defaultValue={this.getDefaultCode()}
                                value={this.props.code}
                                onChange={(code) => {
                                    this.onCodeChange(code)
                                }}
                            />

                            <textarea style={{display: "none"}} name="code" defaultValue={this.state.code}/>
                        </div>
                    </div>

                    <div>
                        <PrimaryButton type={"button"} text={this.props.fn_create ? "创建" : "保存"}
                                       onClick={this.onSave.bind(this)}/>
                        <DefaultButton type={"button"} text={"取消"} onClick={this.props.cancelEdit}/>
                    </div>

                </div>

            </div>

        </div>)
    }
}

export {Function, CodeRunResult};
