import React from "react";
import {ChoiceGroup, PrimaryButton} from "@fluentui/react";
import {CodeRunResult} from "./Function";
import Editor from '@monaco-editor/react';


export default class TestFunction extends React.Component {
    code = ''

    constructor(props) {
        super(props);
        this.state = {
            runtime: 'ns', language: 'python', res: {},
        }
    }

    onCodeChange(code) {
        this.code = code
    }

    run() {
        let req = {'runtime_type': this.state.runtime, 'language': this.state.language, 'code': this.code, 'param': {}}
        fetch(this.backend_path + '/function/run', {
            method: 'POST',
            //mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
        })
            .then(res => res.json())
            .then(res => {
                this.setState({res: res})
            })
    }

    getDefaultCode() {
        if (this.state.language === "python") {
            return "# some code\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"
        } else if (this.state.language === "node") {
            return "// some code"
        } else {
            return ""
        }
    }

    render() {
        return (<div style={{padding: '10px'}}>

                {/*<CodeMirror*/}
                {/*    value={'\n\n\n\n\n\n\n'}*/}
                {/*    options={{*/}
                {/*        theme: 'monokai', keyMap: 'sublime', mode: this.state.language*/}
                {/*    }}*/}
                {/*    onChange={code => this.onCodeChange(code)}*/}
                {/*/>*/}

                <Editor
                    height="50vh"
                    defaultLanguage={this.state.language}
                    defaultValue={this.getDefaultCode()}
                    onChange={(code) => {
                        this.onCodeChange(code)
                    }}
                />

                <div>
                    <ChoiceGroup defaultSelectedKey={this.state.runtime}
                                 options={[{key: 'ns', text: 'ns'}, {key: 'host', text: 'host'},]}
                                 label="选择函数运行时:"
                                 required={true}
                                 onChange={(k, v) => {
                                     this.setState({
                                         runtime: v.key
                                     })
                                 }}
                    />
                </div>

                <div>
                    <ChoiceGroup defaultSelectedKey={this.state.language}
                                 options={[{key: 'python', text: 'python'}, {
                                     key: 'node', text: 'node'
                                 }, {key: 'shell', text: 'shell'}]}
                                 label="选择函数运行环境:"
                                 required={true}
                                 onChange={(k, v) => {
                                     this.setState({
                                         language: v.key
                                     })
                                 }}
                    />
                </div>


                <PrimaryButton text={'Run'} onClick={() => {
                    this.run()
                }}/>

                <CodeRunResult res={this.state.res}/>
            </div>


        )
    }


}
