import React from "react";
import './Endpoint.css'
import {ChoiceGroup, DefaultButton, Label, Link, PrimaryButton, Text} from "@fluentui/react";
import {Checkbox} from '@fluentui/react';
import {Toggle} from '@fluentui/react/lib/Toggle';
import {TextField} from '@fluentui/react/lib/TextField';
import ResourcePanel from "./ResourcePanel";
import {Stack} from "@fluentui/react/lib/Stack";


class Endpoint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getPreview(item) {
        let api_gateway_path = this.api_gateway_path
        return <div style={{margin: '10px'}}>
            <Stack horizontal>
                <Stack>
                    <Label disabled>path</Label>
                    <Label disabled>methods</Label>
                    <Label disabled>type</Label>
                    <Label disabled>target</Label>
                    <Label disabled>url</Label>
                </Stack>

                <Stack>
                    <div style={{minWidth: '20px'}}/>
                </Stack>

                <Stack>
                    <Label>{item['path']}</Label>
                    <Label>{item['methods']}</Label>
                    <Label>{item['type']}</Label>
                    <Label>{item['data']}</Label>
                    <Text><Link href={this.api_gateway_path + item['path']} underline target='_blank '>
                        {api_gateway_path + item['path']}
                    </Link></Text>
                </Stack>
            </Stack>
        </div>
    }

    getCreate(close_function) {
        return <EndpointEdit create={true} close_function={close_function}/>
    }

    getEdit(props, close_function) {
        return <EndpointEdit create={false} path={props.name} close_function={close_function}/>
    }

    componentDidMount() {
    }

    async getEndpointList() {
        return fetch(this.backend_path + '/endpoint/')
            .then(res => res.json())
            .then(json => {
                // convert 'path' to 'name'
                let l = []
                for (let i in json) {
                    let tmp = json[i]
                    tmp.name = tmp.path
                    l.push(tmp)
                }
                return l
            })
    }

    render() {
        return (<ResourcePanel
            getList={this.getEndpointList.bind(this)}
            getCreate={this.getCreate}
            getPreview={this.getPreview.bind(this)}
            getEdit={this.getEdit}/>)
    }
}


class EndpointEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            path: "",
            methods: "GET",
            rs_type: "code",    // select target type
            data: "",           // text field
            mime: "",           // other
            file: undefined,    // upload file
            auth: "none",
            cache: false,
            all_methods_status: {"GET": true, "POST": false, "PATCH": false, "DELETE": false}
        };
        this.all_methods = []
        for (let i in this.state.all_methods_status) {
            this.all_methods.push(i)
        }
        this.file = null
    }

    updateMethods() {
        let new_method = ""
        for (let i in this.state.all_methods_status) {
            if (true === this.state.all_methods_status[i]) {
                new_method += i + ","
            }
        }
        if (new_method.length > 0) {
            new_method = new_method.substring(0, new_method.length - 1)
        }

        this.setState({methods: new_method})
    }

    onSave() {
        let path = this.props.create ? this.state.path : this.props.path
        let url = this.backend_path + "/endpoint" + path //path already has '/'
        let data = {
            path: path,
            methods: this.state.methods,
            rs_type: this.state.mime === '' ? this.state.rs_type : this.state.mime,
            data: this.state.data,
            file: this.state.file,
            auth: this.state.auth,
            cache: this.state.cache,
        }
        this.postData(url, data).then(r => {
            console.log('post endpoint', r)
        })
    }

    selectMethod(method, checked) {
        for (let i in this.state.all_methods_status) {
            if (method === i) {
                let all_methods_status = this.state.all_methods_status
                all_methods_status[i] = checked
                break
            }
        }
        this.updateMethods()
    }

    render() {
        return (<div>

            <div id="ep_form"
                 className={"ep_form"}
            >
                {<div className={"endpoint"}>


                    <TextField label="自定义路径"
                               required
                               description="不要以/结尾"
                               prefix="/"
                               disabled={!this.props.create}
                               defaultValue={this.props.path === undefined ? "" : this.props.path.substring(1)}
                               onChange={(i, j) => {
                                   this.setState({
                                       path: "/" + j
                                   })
                               }}
                    />

                </div>}

                <Label required> 请求方法 :</Label>
                {this.all_methods.map(method => {
                    return <Checkbox label={method}
                                     key={method}
                                     defaultChecked={this.state.all_methods_status[method]}
                                     onChange={(i, j) => {
                                         this.selectMethod(method, j)
                                     }}
                    />
                })}

                <ChoiceGroup defaultSelectedKey={this.state.rs_type}
                             options={[{key: 'code', text: 'code'}, {
                                 key: 'img', text: 'img'
                             }, {key: 'application/json', text: 'json'}, {
                                 key: 'text/plain', text: 'text'
                             }, {key: 'other', text: 'other'}]}
                             label="资源类型:"
                             required={true}
                             onChange={(i, j) => this.setState({rs_type: j.key})}
                />


                <div>
                    {this.state.rs_type === "code" && <div>
                        function name
                        <TextField name="function_name" type="text"
                                   onChange={(i, j) => {
                                       this.setState({
                                           data: j
                                       })
                                   }}
                        />
                    </div>}
                    {this.state.rs_type === "img" && <div>
                        <img alt={""}/>
                        select image file
                        <input name={"file"} type="file" onInput={(e) => {
                            this.setState({
                                file: e.target.files[0]
                            })
                        }}/>
                    </div>}
                    {this.state.rs_type === "application/json" && <div>
                        <textarea name="data" onChange={(e) => {
                            this.setState({data: e.target.value})
                        }}></textarea></div>}
                    {this.state.rs_type === "text/plain" && <div>
                        <textarea name="data" onChange={(e) => {
                            this.setState({data: e.target.value})
                        }}></textarea>
                    </div>}
                    {this.state.rs_type === "other" && <div>
                        <TextField type="text"
                                   onChange={(i, j) => {
                                       this.setState({
                                           mime: j
                                       })
                                   }}
                        />
                        <input name="file" type="file" onInput={(e) => {
                            this.setState({
                                file: e.target.files[0]
                            })
                        }}/>
                    </div>}
                </div>

                <ChoiceGroup defaultSelectedKey={this.state.auth}
                             options={[{key: 'none', text: 'none'}, {key: 'cookie', text: 'cookie'}, {
                                 key: 'header', text: 'header'
                             }]}
                             label="鉴权:"
                             required={true}
                             onChange={(i, j) => {
                                 this.setState({
                                     auth: j.key
                                 })
                             }}
                />

                <Toggle label="缓存" onText="开启" offText="关闭"
                        defaultChecked={this.state.cache}
                        onChange={(i, j) => {
                            this.setState({
                                cache: j
                            })
                        }}
                />


                <div>
                    {this.props.create && <PrimaryButton type="button" onClick={() => this.onSave()} text="创建"/>}
                    {!this.props.create && <PrimaryButton type="button" onClick={() => this.onSave()} text="保存"/>}
                    <DefaultButton type="button" onClick={() => {
                        if (this.props.close_function !== undefined) {
                            this.props.close_function()
                        }
                    }} text="取消"/>
                </div>
            </div>
        </div>)
    }
}

export default Endpoint