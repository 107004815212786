import React from "react";
import './Dashboard.css'
import {ProgressIndicator} from '@fluentui/react/lib/ProgressIndicator';
import {Label, Text} from "@fluentui/react";

class DashBoard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            info: {"0": {cpu: 0, mem: 0, running: 0}},
            workers: [],
            worker_num: 0
        };
    }


    render() {
        return (
            <div className={"dashboard_list"}>
                <div>
                    <Text variant={"xLarge"}>基本信息</Text>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Label disabled>后端地址：</Label><Label>{this.backend_path}</Label>
                    </div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Label disabled>API网关地址：</Label><Label>{this.api_gateway_path}</Label>
                    </div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Label disabled>worker数量：</Label><Label>{this.state.worker_num}</Label>
                    </div>
                </div>

                <div>
                    <Text variant={"xLarge"}>系统负载</Text>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                        {
                            this.state.workers.map((worker, index) => {
                                return <div style={{width: "200px"}} key={index}>
                                    <Label disabled>Node {index}</Label>

                                    <ProgressIndicator label={"CPU"} description={worker.cpu + "%"}
                                                       percentComplete={worker.cpu / 100}/>
                                    <ProgressIndicator label="MEM" description={worker.mem * 100 + "%"}
                                                       percentComplete={worker.mem}/>
                                    <ProgressIndicator label="Containers"
                                                       description={worker.running.toString() + "/" + worker.max.toString()}
                                                       percentComplete={worker.running / worker.max}/>
                                    <br/>
                                </div>
                            })
                        }
                    </div>

                </div>
                <div>

                </div>
            </div>

        )
    }

    get_info() {
        fetch(this.backend_path + '/function/all_worker_info')
            .then(res => res.json())
            .then(res => {
                let worker_num = 0
                let workers = []
                for (let each_worker in res) {
                    worker_num++
                    workers.push(res[each_worker])
                }
                this.setState({info: res, worker_num: worker_num, workers: workers})
            })
    }

    componentDidMount() {
        // setInterval(() => {
        //     this.get_info()
        // }, 1000)
        this.get_info()
    }
}

export default DashBoard
