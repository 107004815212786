import React from "react";
import ResourcePanel from "./ResourcePanel";

export default class Timer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<ResourcePanel
        />);
    }
}
