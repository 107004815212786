import React from "react";

import './App.css';

import {Function} from "./Function";
import DashBoard from "./DashBoard";
import Endpoint from "./Endpoint";
import StatefulApp from "./StatefulApp";
import TestFunction from "./TestFunction";
import Timer from "./Timer"
import Log from "./Log";


import {Persona, PersonaSize, Pivot, PivotItem} from '@fluentui/react';
import {initializeIcons} from '@fluentui/react/lib/Icons';
import {Stack} from "@fluentui/react/lib/Stack";
import {Label} from "@fluentui/react/lib/Label";


initializeIcons("https://static2.sharepointonline.com/files/fabric/assets/icons/");

React.Component.prototype.backend_path = 'https://fn-be.one-api.net/backend'
React.Component.prototype.api_gateway_path = 'https://fn-be.one-api.net/api'

React.Component.prototype.postData = postData

function jsonToForm(json) {
    let formData = new FormData()
    for (let i in json) {
        formData.append(i, json[i])
    }
    return formData
}

async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        // headers: {
        //     //'Content-Type': 'application/json'
        //     'Content-Type': 'application/x-www-form-urlencoded',
        // },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //body: JSON.stringify(data) // body data type must match "Content-Type" header
        body: jsonToForm(data)
    })
    return response.text()
}

class App extends React.Component {

    render() {

        return (<div style={{height: '100%'}} id={'app'}>

            <div>
                <Stack horizontal style={{width: "100%"}}>
                    <h1 style={{minWidth: '200px'}}> Serverless Function</h1>
                    <Stack horizontal horizontalAlign="end" style={{width: "100%"}}>
                        <Persona text="User" size={PersonaSize.size24}/>
                    </Stack>
                </Stack>
            </div>
            <Pivot linkSize="large">
                <PivotItem headerText="仪表板">
                    <DashBoard/>
                </PivotItem>
                <PivotItem headerText="函数">
                    <Function/>
                </PivotItem>
                <PivotItem headerText="API网关">
                    <Endpoint/>
                </PivotItem>
                <PivotItem headerText="定时器">
                    <Timer/>
                </PivotItem>
                <PivotItem headerText="状态应用">
                    <StatefulApp/>
                </PivotItem>
                <PivotItem headerText="日志">
                    <Log/>
                </PivotItem>
                <PivotItem headerText="函数测试">
                    <TestFunction/>
                </PivotItem>
            </Pivot>


        </div>);
    }

    componentDidMount() {
        //fetch()
    }
}

export default App;
